import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/cap.png",
    title: "Educational Foundation",
    content:
      " Computer Science at Pace University",
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/code.png",
    title: "Programming Expertise",
    content:
      "Proficient in Swift, JavaScript, Python, HTML, and CSS..",
    color: "#4CAF50",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/pen.png",
    title: "Graphic Design Skills",
    content:
      "Good with Photoshop, Illustrator, and InDesign.",
    color: "#F97B8B",
    contentColor: "light",
  },
  {
    id: 4,
    icon: "images/marketing.png",
    title: "Marketing Savvy",
    content:
      "Experienced in marketing strategies for businesses.",
      color: "#F97B8B",
    contentColor: "light",
  },
  {
    id: 5,
    icon: "images/app.png",
    title: "App Development Mastery",
    content:
      "Created diverse apps, including social media, AI artwork generators, PDF scanners, and AR apps. Expertise in both front-end and back-end development. Known for creating aesthetically pleasing and user-friendly apps.",
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 6,
    icon: "images/solo.png",
    title: "Exceptional Solo Performer",
    content:
      "Accomplished professional, excelling in diverse roles and responsibilities.",
    color: "#4CAF50",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Background" />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">  
            Wanna see my latest start-up?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link>{" "}
            to check it out! 🚀 
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
