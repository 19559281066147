import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Alexander Bowser",
  avatarImage: "/images/header-2.png",
  content:
    "I turn ideas into reality by embracing multiple roles: coding expert, designer, and business strategist.",
};

const progressData = [
  {
    id: 1,
    title: "Software Development",
    percantage: 95,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Digital Marketing/Automation",  
    percantage: 85,
    progressColor: "#FF4C60",
  },

  {
    id: 4,
    title: "Business Strategy",
    percantage: 70,
    progressColor: "#6C6CE5",
  },
];

const counterData = [
  {
    id: 1,
    title: "Apps built +",
    count: 800,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Users reached +",
    count: 10300,
    icon: "icon-user",
  },
  // {
  //   id: 3,
  //   title: "Goals Attained",
  //   count: 100,
  //   icon: "icon-trophy",
  // },
  // {
  //   id: 4,
  //   title: "",
  //   count: 35,
  //   icon: "icon-badge",
  // },
];

// Add a plus sign after count in the array


function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="roundedgonna be ok shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div className="mt-3">
                    <a href="!#" className="btn btn-default">
                    Check my new app
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
