import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const experienceData = [
  {
    id: 0,
    title: "",
    years: "Current Age: 28 Years Old",
    content: "",
  },
  {
    id: 1,
    title: "MyWorksDone",
    years: "2015 - 2018",
    content:
      "During my college years, I developed a website that facilitated peer-to-peer payments among students for completing homework and assignments.",
  },
  {
    id: 2,
    title: "Epic Boost",
    years: "2017 - 2020",
    content:
      "Founded and operated Epic Boost, a business that offered gamers the opportunity to hire other gamers for playing their video games and availing in-game goods and services.",
  },
  {
    id: 3,
    title: "Viral Studio",
    years: "2020 - Present",
    content:
      "Currently, I run Viral Studio, a successful business specializing in website development and comprehensive marketing campaigns for small businesses.",
  },
  {
    id: 4,
    title: "Mobile App Development",
    years: "2019 - Present",
    content:
      "Additionally, I have independently developmented numerous mobile apps alongside my work on websites and marketing campaigns for small businesses.",
  },
];
const educationData = [
  {
    id: 1,
    title: "Pace University - Bachelor of Science in Computer Science",
    years: "2017",
    content:
      "Earned a Bachelor's degree in Computer Science from Pace University.",
  },
  {
    id: 2,
    title: "Self-Taught Programmer",
    years: "2018 - present",
    content:
      "Dedicated to self-learning various programming languages, including Python, Swift, Javascript, HTML, CSS, and more.",
  },
  {
    id: 3,
    title: "Proficiency in Multiple Programming Languages",
    years: "2020",
    content:
      "Achieved expertise in coding across multiple programming languages.",
  },
  {
    id: 4,
    title: "Additional Pursuits",
    years: "2014 - present",
    content:
      "Engaged in various other activities and interests alongside my programming journey. I have created over 10 mobile apps in use today and thousands of other non deployed apps.",
  },
];


function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
        <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline expgonna be ok rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="timeline edugonna be ok rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>


        </div>
      </div>
    </section>
  );
}

export default Experiences;
