import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Pricebox from "../elements/Pricebox";

const priceboxData = [
  {
    id: 1,
    title: "Pets AI",
    icon: "images/pets-ai-logo.png",
    content: [
      {
        id: 0,
        text: "Best AI Art for Pets",
      },
      {
        id: 2,
        text: "Pet Social Networking",
      },
    ],


  },
  {
    id: 2,
    title: "gonna be ok",
    icon: "images/gbo-logo.png",
    content: [
      {
        id: 1,
        text: "AI for Health Care",
      },
      {
        id: 2,
        text: "Social Networking",
      },
    ],
    best: true,
    badge: "Brand new app!",
  },
  {
    id: 3,
    title: "Open Religion",
    icon: "images/Open-Religion-Dark-Logo.png",
    content: [
      { id: 1, text: "All world religions" },
      { id: 2, text: "Unity" },
    ],

  },
];

function Pricing() {
  return (
    <section id="prices">
      <div className="container">
        <Pagetitle title="Latest Apps" />
        <div className="row align-items-center">
          {priceboxData.map((pricebox) => (
            <div key={pricebox.id} className="col-md-4">
              <Pricebox priceboxItem={pricebox} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Pricing;
